import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import { Box, Paper } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button, FormHelperText, Grid } from "@mui/material";

const Schedule01 = ({setSchedule}) => {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState('');
    const [isEnter, setEnter] = useState(false)

    const handleDate = (event) => {
        setSelectedDate(event.target.value);


    }

    console.log(selectedDate)

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedDate && rateCal) {
            window.sessionStorage.setItem("schedule", JSON.stringify(selectedDate));
            window.sessionStorage.setItem("delType", JSON.stringify(rateCal.deliveryType));
            setSchedule(true)
            setEnter(true);
        }
        else {
            alert("please select date")
        }
    }
    const dAddress = JSON.parse(sessionStorage.getItem('deliveryDetail'));
    const pAddress = JSON.parse(sessionStorage.getItem('pickDetail'));
    const packages = JSON.parse(sessionStorage.getItem('package'));
    const price = JSON.parse(sessionStorage.getItem('price'))
    const [rateCal, setrateCal] = useState({
        deliveryType: "",
    })
    console.log(rateCal.deliveryType)

    const handleChange1 = (e) => {
        setrateCal(prev => ({ ...prev, [e.target.name]: e.target.value }))
    };




    return (
        <>
            <div className="grid grid-cols-1 lg:grid-cols-2">
                <div data-aos="flip-right" className="flex p-3 justify-center  items-center">
                    <div className="border bg-[#F8FEFF] border-grey-200 rounded-lg p-5 max-w-[700px] shadow-lg">
                        <p className="pt-5  pl-2 text-2xl font-[Dosis] text-[#EF8038]">Charges and Pickup Schedule</p><hr />




                        <Box textAlign={"center"} >

                            {price == "Invalid Data" ? (<>
                                <Box m={2}>
                                    <FormControl sx={{ pt: 2 }}>

                                        <RadioGroup
                                            onChange={handleChange1}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="deliveryType"
                                        >
                                            <Box>
                                                <Grid container><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="stdRate" control={<Radio />} label="Pickupkart Standard" /></Grid>
                                                    <Grid xs={12} md={6}> <TextField
                                                        sx={{ alignSelf: 'end' }}
                                                        id="filled-read-only-input"
                                                        label="Pickupkart Standard"
                                                        value="₹ 0"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="filled"
                                                    /></Grid></Grid>
                                                <Grid container pt={2}><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="groundRate" control={<Radio />} label="Pickupkart Ground" /></Grid>
                                                    <Grid xs={12} md={6}> <TextField
                                                        sx={{ alignSelf: 'end' }}
                                                        id="filled-read-only-input"
                                                        label="Pickupkart Ground"
                                                        value="₹ 0"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="filled"
                                                    /></Grid></Grid>
                                                <Grid container pt={2}><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="cargoRate" control={<Radio />} label="Pickupkart Cargo" /></Grid>
                                                    <Grid xs={12} md={6}><TextField
                                                        sx={{ alignSelf: 'end' }}
                                                        id="filled-read-only-input"
                                                        label="Pickupkart Cargo"
                                                        value="₹ 0"
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="filled"
                                                    /></Grid></Grid>
                                            </Box>
                                        </RadioGroup>
                                    </FormControl>
                                </Box>


                            </>
                            ) : (
                                <>
                                    <Box m={2}>
                                        <FormControl sx={{ pt: 2 }}>

                                            <RadioGroup
                                                onChange={handleChange1}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="deliveryType"
                                            >
                                                <Box>
                                                    <Grid container><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="stdRate" control={<Radio />} label="Pickupkart Standard" /></Grid>
                                                        <Grid xs={12} md={6}> <TextField
                                                            sx={{ alignSelf: 'end' }}
                                                            id="filled-read-only-input"
                                                            label="Pickupkart Standard"
                                                            value={price}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            variant="filled"
                                                        /></Grid></Grid>
                                                    <Grid container pt={2}><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="groundRate" control={<Radio />} label="Pickupkart Ground" /></Grid>
                                                        <Grid xs={12} md={6}> <TextField
                                                            sx={{ alignSelf: 'end' }}
                                                            id="filled-read-only-input"
                                                            label="Pickupkart Ground"
                                                            value={price}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            variant="filled"
                                                        /></Grid></Grid>
                                                    <Grid container pt={2}><Grid xs={12} md={6} textAlign={"left"}><FormControlLabel value="cargoRate" control={<Radio />} label="Pickupkart Cargo" /></Grid>
                                                        <Grid xs={12} md={6}><TextField
                                                            sx={{ alignSelf: 'end' }}
                                                            id="filled-read-only-input"
                                                            label="Pickupkart Cargo"
                                                            value={price}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            variant="filled"
                                                        /></Grid></Grid>
                                                </Box>
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                </>)}
                        </Box>
                        <hr />




                        <div className="flex justify-between p-4 mt-3 text-center pt-3 mb-2">
                            <div className="pt-0 md:p-2"> <p className="text-xl font-[Dosis]">Pickup Schedule:</p></div>
                            <div className="text-center">
                                <input
                                    type="datetime-local"
                                    required
                                    name="pickupdatetime"
                                    onChange={handleDate}
                                    className="p-2 transition-all duration-200 border border-gray-200 shadow-md rounded-lg font-mullish outline-lightBlue placeholder:text-base max-[475px]:placeholder:text-xs"
                                />

                            </div>
                        </div>

                        <form className="  pl-0 max-[475px]:pl-0 px-1  mb-4">
                            {/* <img src={Vectorright} className="absolute right-7 top-5 max-[1024px]:right-8" alt=""/>  */}


                            {isEnter ? (<div className="flex justify-center">
                                <button
                                    className="text-green-500 w-[133px] max-[475px]:text-[13px] max-[475px]:w-[120px] max-[475px]:h-[40px] h-[40px] bg-[#EDEDED] text-black text-[16px] font-semibold font-ROBOTO  mt-5 rounded-lg mb-4"

                                >
                                    Submitted
                                </button></div>
                            ) : (<div className="flex justify-center">
                                <button type="submit"
                                    className="w-[133px] max-[475px]:text-[13px] max-[475px]:w-[120px] max-[475px]:h-[40px] h-[40px] bg-[#EDEDED] text-black text-[16px] font-semibold font-ROBOTO mt-5 rounded-lg mb-4"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </button></div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="lg:flex lg:justify-end p-3 items-center">
                    <Box>
                        <Accordion>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                sx={{ backgroundColor: "#0260AA" }}
                            >
                                <Typography fontFamily={"Dosis"} color={"white"}>Order Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="p-2 lg:w-[500px] shadow-lg border border-gray-200 rounded-lg text-white bg-[#0260AA]">
                                    <div className="orderSummary">
                                        <h1 className="text-[30px] font-[Dosis]">Order Summary</h1>
                                        <hr className="mb-5" />
                                        <div className="p-4 font-[Poppins]">
                                            <h5 className="text-[20px]">Address Details:</h5>
                                            <div className="pl-5">
                                                <h6 >
                                                    {pAddress ? (<>   <span className="text-[#EF8038]">Pickup address</span> :<br /> {pAddress.pcontactname},{pAddress.phouse},{pAddress.parea},{pAddress.pcity},{pAddress.pstate},{pAddress.ppin}</>) : (<span></span>)}
                                                </h6>
                                                <h6 className="mt-2">{dAddress ? (<> <span className="text-[#EF8038]">Delivery Address </span>:<br />  {dAddress.dcontactname},{dAddress.dhouse},{dAddress.darea},{dAddress.dcity},{dAddress.dstate},{dAddress.dpin}</>) : (<span></span>)}</h6>
                                            </div>
                                            <div className="mt-4">
                                                <h5 className="text-[20px]">Package Details:</h5>
                                                <h6>
                                                    {packages ? (<> <span className="text-[#EF8038] pl-5">Packge Type</span> : {packages.shiptype}</>) : (<span></span>)}
                                                </h6>
                                                <h6>
                                                    {packages ? (<><span className="text-[#EF8038] pl-5">Packge weight</span> : {packages.weight} {packages.unit}</>) : (<span></span>)}
                                                </h6>
                                                <h6>
                                                    {packages ? (<><span className="text-[#EF8038] pl-5">Packge description</span> : {packages.description} </>) : (<span></span>)}
                                                </h6>
                                                <h6>
                                                    {packages ? (<><span className="text-[#EF8038] pl-5">Packge Dimension</span> : {packages.length}cm X {packages.width}cm X {packages.height}cm </>) : (<span></span>)}
                                                </h6>
                                                <h6>
                                                    {packages ? (<><span className="text-[#EF8038] pl-5">Product Value</span> : ₹{packages.productValue} </>) : (<span></span>)}
                                                </h6>
                                                <h6>
                                                    {packages ? (<><span className="text-[#EF8038] pl-5">Number of Parcels</span> : {packages.numberOfParcels} </>) : (<span></span>)}
                                                </h6>
                                            </div>
                                           

                                        </div>
                                    </div>
                                    {/* <div className="flex justify-center"> <button className="w-[133px] createPickup1 max-[475px]:text-[13px] max-[475px]:w-[140px] max-[475px]:h-[40px] h-[30px] bg-[#EDEDED] text-black text-[16px] font-semibold font-ROBOTO  mt-5 rounded-lg mb-4" onClick={handleNext}>Next</button></div> */}
                                </div>
                            </AccordionDetails>
                        </Accordion></Box>
                </div>

            </div>
        </>
    )
}

export default Schedule01;