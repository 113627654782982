import React from "react";
import { Link } from "react-router-dom";

// images
import fblogo from "./Images/fb-logo.png";
import instalogo from "./Images/insta-logo.png";
import pickupkart1 from "./Images/pick.png";
import twitterlogo from "./Images/twitter-logo.png";
import messagediv from "./Images/message-div.png";
import bottompersonbg from "./Images/bottom-person-bg.png";
import personimgbottom from "./Images/person-img-bottom.png";
import forwardarrow from "./Images/forward-arrow.png";
// images end


const Footer = () => {

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <>
            {/* <!-- Footer Section --> */}
            <section>




                {/* <!-- Bottom part --> */}
                <div className="relative" >
                    <div className="bg-[rgb(8,33,80,0.9)] z-0  w-full h-full absolute left-0 top-0 "></div>
                    <div className="bg-[url('')] mx-auto w-full h-full justify-start flex flex-col bg-no-repeat bg-cover gap-8 img4">
                        <div className="flex z-[500] flex-row w-[70%] mx-auto gap-20  justify-between pt-12 max-[1024px]:gap-14 max-[1024px]:w-full max-[640px]:flex-wrap max-[640px]:justify-center max-[640px]:gap-24">

                            <div className="flex flex-col gap-5 w-[20%] max-[640px]:w-[30%]">
                                <div className="max-[1024px]:pl-2">
                                    <div className="flex flex-row gap-8  mr-1 ">
                                        <img src={pickupkart1} className="max-[640px]:-ml-5 -ml-3 max-[768px]:w-[160px]" width="200" alt="" />

                                    </div>
                                </div>

                                <div className="text-white font-ROBOTO max-[1024px]:pl-2 max-[640px]:w-[130%]">
                                    We make the things you need arrive on time. You focus on what you need to do.
                                </div>

                                <div className="flex flex-row items-center gap-3 max-[1024px]:pl-2">
                                    <div className="w-[30px] h-[28px] rounded-full bg-white flex items-center">
                                        <a href=""><img src={fblogo} className="pl-[0.62rem]" alt="" /></a>
                                    </div>
                                    <div className="w-[30px] h-[28px] rounded-full bg-white flex items-center">
                                        <a href=""> <img src={instalogo} className="pl-[0.5rem]" alt="" /></a>
                                    </div>
                                    <div className="w-[30px] h-[28px] rounded-full bg-white flex items-center">
                                        <a href=""><img src={twitterlogo} className="pl-[0.5rem]" alt="" /></a>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-1 text-white max-[1024px]:pl-8">
                                <p className="text-[24px] text-[#EF8038] font-semibold font-ROBOTO pb-2">Explore</p>
                                <Link onClick={scrollTop} className="text-[18px] font-medium font-ROBOTO" to="/">Home</Link>
                                <Link onClick={scrollTop} className="text-[18px] font-medium font-ROBOTO" to="/About">About Us</Link>
                                <Link onClick={scrollTop} className="text-[18px] font-medium font-ROBOTO" to="/Services">Services</Link>
                                <Link onClick={scrollTop} className="text-[18px] font-medium font-ROBOTO" to="/Contact">Contact Us</Link>
                            </div>

                            <div>
                                <p className="text-[24px] font-semibold text-[#EF8038] font-ROBOTO  pb-2">Subscribe</p>
                                <form className="relative mt-2 mb-4">
                                    <div className="absolute w-[50px] h-[50px] rounded-full bg-black right-0">
                                        <img src={forwardarrow} className="pt-4 pl-4" alt="" />
                                    </div>
                                    <input placeholder="Your Email Address"
                                        className="pl-4  py-3 pr-24 transition-all duration-200 border border-gray-200 shadow-md rounded-[2rem] font-mullish outline-lightBlue placeholder:text-sm " />
                                </form>
                            </div>

                        </div>


                        <div className="flex z-[500] flex-row gap-2  justify-center items-center pt-12 mx-auto max-[1024px]:w-full max-[1024px]:pl-1  max-[913px]:flex-col max-[768px]:w-full max-[768px]:mx-auto max-[913px]:gap-5 max-[768px]: ">

                            <div className="flex flex-row gap-5 rounded-lg bg-[#00103A] hover:bg-[#00081D] transition duration-500 ease-in-out pl-5 pt-5 pr-32 pb-5  max-[768px] max-[1024px]:">
                                <div className="flex justify-center items-center mr-5 p-3"><i className="fa-solid fa-location-dot text-white text-[30px] "></i></div>

                                <div className="text-center">
                                    <p className="text-[#EF8038] font-semibold font-ROBOTO text-[22px] max-[640px]:text-[18px]">Location</p>
                                    <p className="text-white font-medium font-ROBOTO text-[18px] w-full text-center max-[640px]:text-[14px]">Karad</p>
                                </div>
                            </div>

                            <div  className="flex rounded-lg flex-row gap-5 mx-auto items-center bg-[#00081D] hover:bg-[#00103A] transition duration-500 ease-in-out pl-5 pt-5 pr-32 pb-5 max-[1024px]:pl-4 max-[1024px]:pr-28">
                                <div className="flex justify-center items-center mr-5 p-3"><i className="fa-solid fa-envelope text-white text-[30px] "></i></div>

                                <div className="text-center">
                                    <p className="text-[#EF8038] font-semibold font-ROBOTO text-[22px] max-[640px]:text-[18px]">Email</p>
                                    <p className="text-white font-medium font-ROBOTO text-[18px] max-[640px]:text-[14px]">contact@pickupkart.in</p>
                                </div>
                            </div>

                            <div  className="flex flex-row rounded-lg gap-5 bg-[#00103A] hover:bg-[#00081D] transition duration-500 ease-in-out pl-5 pt-5 pr-32 pb-5 max-[1024px]:pl-4 max-[1024px]:pr-28">
                                <div className="flex justify-center items-center mr-5 p-3"><i className="fa-solid fa-phone text-white text-[30px] "></i></div>

                                <div className="text-center">
                                    <p className="text-[#EF8038] font-semibold font-ROBOTO text-[22px] max-[640px]:text-[18px]">Phone</p>
                                    <p className="text-white font-medium font-ROBOTO text-[18px] w-full text-center max-[640px]:text-[14px]">+917249174747</p>
                                </div>
                            </div>

                        </div>

                        <div className="h-1 bg-[#96431582] w-full"></div>

                        <div className="text-white flex justify-center items-center h-[40px] bg-[#0260AA] z-[500] font-medium font-ROBOTO text-[15px]   max-[1024px]:pl-[10rem] max-[768px]:pl-[4rem] max-[640px]:pl-[1rem]">
                            @ 2023 Venus Express & Logistics | Designed & developed by 5Tech Lab
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer;